<template>
    <div class="show-menu">
        <a href="#" class="shadow-top-down">+</a>
    </div>
    <nav class="main-menu shadow-top-down">
        <ul class="nav nav-pills nav-stacked">
            <li><a href="#templatemo_home" class="scroll_effect">Accueil</a></li>
            <li><a href="#templatemo_features" class="scroll_effect">Paiement</a></li>
            <li><a href="#templatemo_contact" class="scroll_effect">Inscription</a></li>
        </ul>
    </nav>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MenuCircle',
});
</script>