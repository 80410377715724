<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-xs-12 footer-copyright">
                    <p>Copyright &copy; 2024 <a href="#" target="_parent">Horama Groups</a></p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: '',
})
</script>