<template>
    <section id="templatemo_contact">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <header class="template_header">
                        <h1 class="text-center"><span>...</span> Inscription <span>...</span></h1>
                    </header>
                </div>
            </div>
            <div v-if="loading == false">

                <form v-on:submit="submit()">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-addon"><i class="fa fa-number"></i></div>
                            <input v-model="code" type="text" name="name" class="form-control" id="contact-name"
                                placeholder="Inscrivez le code d'inscription" style="color: black;" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-xs-6 col-xs-offset-6">
                            <button type="submit" class="form-control">Valider</button>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <header class="template_header">
                            <h1 class="text-center"></h1>
                        </header>
                    </div>
                </form>
            </div>

            <!--  -->
            <div v-else>
                <square color="#fff"></square>
            </div>
        </div>
    </section>
</template>

<script>
import { toast } from "@/assets/utils/toast.js"
import { defineComponent } from 'vue';
import axios from "axios";


export default defineComponent({
    name: 'InscriptionSection',

    data() {
        return {
            code: null,
            //
            loading: false,
        }
    },

    methods: {
        async submit() {
            this.loading = true;
            await axios.post(this.$store.state.URL_API + "/inscription/initiate", {
                code: this.code,
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.status) {
                        if (res.data.data.inscriptionToken) {
                            this.$router.push({ name: 'inscription', params: { token: res.data.data.inscriptionToken, id: 'none' } });
                        } else {
                            this.$router.push({ name: 'inscription', params: { id: res.data.data.payment.inscription.id, token: 'none' } });
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Erreur 500', {
                        position: top,
                        dismissible: true
                    });
                })
            this.loading = false;
        },
    }
});
</script>