<template>
    <section id="templatemo_element" v-if="solded">
        <div v-if="!loading">
            <div class="container" v-if="!over">

                <header class="template_header">
                    <h1 class="text-center"> Formulaire d'inscription </h1>
                </header>


                <div v-if="formChild">

                    <form class="col-md-6" id="left-form">
                        <div class="col-xs-12 row">
                            <div class="form-group">
                                <label for="names">Nom et prénoms (enfant)</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-user"></i></div>
                                    <input v-model="form.nom" type="text" class="form-control" id="names"
                                        placeholder="Ex: Assamoi Ange Emmanuel" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="old">Âge</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-sort-numeric-asc"></i></div>
                                    <input v-model="form.age" type="number" class="form-control" id="old"
                                        placeholder="Ex: 10" min="0" max="17" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="phone">Numéro WhatsApp</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-phone"></i></div>
                                    <input v-model="form.phone" type="text" class="form-control" id="phone"
                                        placeholder="Ex: 0102030405" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="option">Baptême</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-list-ul"></i></div>
                                    <select v-model="form.baptism" class="form-control" id="option" required>
                                        <option value="Non baptisé(e)">Pas baptisé(e)</option>
                                        <option value="Eau">Baptême d'eau</option>
                                        <option value="Saint-Esprit">Baptême du Saint-Esprit</option>
                                        <option value="Les deux">Les deux</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="handicap">Handicap (préciser si oui)</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-font"></i></div>
                                    <input v-model="form.handicap" type="text" class="form-control" id="handicap"
                                        placeholder="Facultatif" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="alergie">Information sanitaire (allergie)</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-font"></i></div>
                                    <input v-model="form.allergy" type="text" class="form-control" id="alergie"
                                        placeholder="Facultatif" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="father">Nom et contact du père</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-user"></i></div>
                                    <input v-model="form.father" type="text" class="form-control" id="father"
                                        placeholder="Ex: Père 0102030405" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="monitor">Nom et contact du moniteur d'assemblée</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-user"></i></div>
                                    <input v-model="form.monitor" type="text" class="form-control" id="monitor"
                                        placeholder="Ex: Mère 0102030405" required>
                                </div>
                            </div>
                        </div>
                    </form>

                    <!--  -->

                    <form class="col-md-6 mx-4" id="right-form">
                        <div class="col-xs-12 row">
                            <div class="form-group">
                                <label for="option">Secteur</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-list-ul"></i></div>
                                    <select v-model="form.sector" class="form-control" id="option" required>
                                        <option>Secteur A</option>
                                        <option>Secteur B</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="option">District</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-list-ul"></i></div>
                                    <select v-model="form.district" class="form-control" id="option" required>
                                        <option>Distric A</option>
                                        <option>Distric B</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="option">Sexe</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-list-ul"></i></div>
                                    <select v-model="form.gender" class="form-control" id="option" required>
                                        <option>Masculin</option>
                                        <option>Feminin</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="option">Assemblée</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-list-ul"></i></div>
                                    <select v-model="form.assembly" class="form-control" id="option" required>
                                        <option>Assemblée 1</option>
                                        <option>Assemblée 2</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="p-spirit">Problème(s) spirituel(s)</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-font"></i></div>
                                    <input v-model="form.spirit" type="text" class="form-control" id="p-spirit"
                                        placeholder="Facultatif" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="p-psycho">Probleme(s) psychologique(s)</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-font"></i></div>
                                    <input v-model="form.psycho" type="text" class="form-control" id="p-psycho"
                                        placeholder="Facultatif" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="mother">Nom et contact de la mère</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-user"></i></div>
                                    <input v-model="form.mother" type="text" class="form-control" id="mother"
                                        placeholder="Ex: Moniteru Wwilfried 0102030405" required>
                                </div>
                            </div>
                        </div>
                    </form>


                    <center>
                        <button type="button" v-on:click="submit()" id="button2"
                            class="btn btn-primary">Enregistrer</button>
                    </center>

                </div>

                <!--  -->

                <div v-else>


                    <form class="col-md-6" id="form1">
                        <div class="col-xs-12 row">
                            <div class="form-group">
                                <label for="names">Nom et prénoms</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-user"></i></div>
                                    <input v-model="form.nom" type="text" class="form-control" id="names"
                                        placeholder="Ex: Assamoi Ange Emmanuel" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="old">Âge</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-sort-numeric-asc"></i></div>
                                    <input v-model="form.age" type="number" class="form-control" id="old"
                                        placeholder="Ex: 10" min="0" max="17" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="phone">Numéro WhatsApp</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-phone"></i></div>
                                    <input v-model="form.phone" type="text" class="form-control" id="phone"
                                        placeholder="Ex: 0102030405" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="annee">Année d'expérience</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-sort-numeric-asc"></i></div>
                                    <input v-model="form.experience" type="number" class="form-control" id="annee"
                                        placeholder="Ex: 3" min="0" max="20" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="nbpart">Nombre de participation au camp des enfants</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-sort-numeric-asc"></i></div>
                                    <input v-model="form.participation" type="number" class="form-control" id="nbpart"
                                        placeholder="Ex: 3" min="0" max="20" required>
                                </div>
                            </div>

                        </div>
                    </form>

                    <!--  -->

                    <form class="col-md-6 mx-4" id="form2">
                        <div class="col-xs-12 row">
                            <div class="form-group">
                                <label for="option">Secteur</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-list-ul"></i></div>
                                    <select v-model="form.sector" class="form-control" id="option" required>
                                        <option>Secteur A</option>
                                        <option>Secteur B</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="option">District</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-list-ul"></i></div>
                                    <select v-model="form.district" class="form-control" id="option" required>
                                        <option>Distric A</option>
                                        <option>Distric B</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="option">Assemblée</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-list-ul"></i></div>
                                    <select v-model="form.assembly" class="form-control" id="option" required>
                                        <option>Assemblée 1</option>
                                        <option>Assemblée 2</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="option">Sexe</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-list-ul"></i></div>
                                    <select v-model="form.gender" class="form-control" id="option" required>
                                        <option>Masculin</option>
                                        <option>Feminin</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                            </div>

                        </div>
                    </form>

                    <center>
                        <button type="button" v-on:click="submit()" id="button2"
                            class="btn btn-primary">Enregistrer</button>
                    </center>
                </div>

            </div>

            <div class="container" v-else>
                <header class="template_header">
                    <h1 class="text-center"> Votre inscription est terminée </h1>
                </header>

                <center>
                    <button type="button" v-on:click="printFile()" class="btn btn-success">
                        Imprimer ma fiche
                    </button>
                </center>
                <hr>
                <center>
                    <button type="button" v-on:click="$router.push({ path: '/' })" class="btn btn-primary">
                        Retour à l'acceuil
                    </button>
                </center>
            </div>

        </div>

        <div class="container" v-else>
            <square color="#000"></square>
        </div>
    </section>


    <section id="templatemo_features" v-else>
        <div class="container-fluid">

            <header class="template_header">
                <h1 class="text-center">
                    <a v-on:click="stepback()" v-if="step != 1 && step != 3" class="btn" style="height: 100%;">Retour</a>

                    Terminer mon paiement
                </h1>
            </header>

            <div v-if="!loading">

                <div v-for="slice in slices" v-key="slice.id" class="col-lg-12"
                    style="align-items: center; justify-content: center; display: flex;" v-if="step == 1">

                    <!--  -->
                    <div class="col-sm-9 col-lg-6 feature-box"
                        v-if="slice.paid && slices.indexOf(slice) != slices.length - 1">
                        <div class="feature-box-inner" style="background-color: rgb(79, 150, 98);">
                            <p style="font-size: 30px; font-weight: 600;">
                                Payé
                            </p>
                            <p>
                                {{ cashFormat(slice.slice.amount) }}
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-9 col-lg-6 feature-box" v-else>
                        <div class="feature-box-inner">
                            <p>
                                {{ cashFormat(slice.slice.amount) }}
                            </p>

                            <div class="templatemo_home_inner_wapper btn_wapper">
                                <div class="col-sm-12">
                                    <a v-on:click="selectSlice(slice.slice.id)"
                                        class="btn col-xs-12 scroll_effect shadow-top-down">
                                        Payer maintenant
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  -->

                <form v-on:submit="pay()" v-else-if="step == 2">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-addon"><i class="fa fa-phone"></i></div>
                            <input v-model="userPhone" type="text" name="name" class="form-control" id="contact-name"
                                placeholder="Inscrivez votre numéro de téléphone" style="color: white;" required
                                maxlength="10" minlength="10">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-xs-6 col-xs-offset-6">
                            <button type="submit" class="form-control" style="color: white;">Je paie</button>
                        </div>

                    </div>
                    <div class="col-sm-12">
                        <header class="template_header">
                            <h1 class="text-center"></h1>
                        </header>
                    </div>
                </form>

                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;" v-else>
                    <p class="text-center" style="color: azure; font-weight: bold;">Paiment effectué avec succès.<br></p>
                    <!--  -->
                    <div class="templatemo_home_inner_wapper btn_wapper">
                        <div class="col-sm-12">
                            <a v-on:click="$router.push({ path: '/' })" class="btn col-xs-12 scroll_effect shadow-top-down">
                                Procéder à l'inscription
                            </a>
                        </div>
                    </div>
                </div>

            </div>

            <!--  -->
            <div v-else>
                <square color="#fff"></square>
            </div>


        </div>
    </section>
</template>

<script>

import { cashFormat } from '@/assets/utils/cash-utils.js';
import { toast } from "@/assets/utils/toast.js";
import { defineComponent } from 'vue';
import axios from "axios";

export default defineComponent({
    name: 'InscriptionView',
    components: {

    },

    data() {
        return {
            loading: true,
            //
            solded: false,
            //
            selectedSliceId: 0,
            //
            userPhone: null,
            //
            payment: {},
            //
            inscription: {},
            //
            slices: [],
            //
            step: 1,
            //
            formChild: false,
            //
            over: false,
            //
            form: {
                participation: null,
                experience: null,
                handicap: null,
                district: null,
                assembly: null,
                monitor: null,
                baptism: null,
                allergy: null,
                mother: null,
                father: null,
                sector: null,
                gender: null,
                spirit: null,
                psycho: null,
                phone: null,
                nom: null,
                age: null,
            }
        }
    },

    mounted() {
        this.check();
    },

    methods: {
        cashFormat,

        load(action, time = 1500) {
            this.loading = true;
            setTimeout(() => {
                action();
                this.loading = false;
            }, time);
        },

        selectSlice(id) {
            this.load(() => {
                this.selectedSliceId = id
                this.step++;
            });
        },

        stepback() {
            this.step--;
            this.selectedSliceId = 0;
        },

        async check() {
            this.loading = true;

            if (this.$route.params.token == "none") {
                this.solded = false;
                await axios.post(this.$store.state.URL_API + "/inscription/check", {
                    id: this.$route.params.id
                })
                    .then((res) => {
                        if (res.data.status) {
                            this.inscription = res.data.data;
                            this.payment = res.data.data.payment;

                            const pack = this.payment.pack;

                            var finals = [];
                            const totals = res.data.data.payment.pack.slices;
                            const partials = res.data.data.payment.slices;

                            let partialSum = 0;
                            partials.forEach((el) => {
                                partialSum += parseInt(el.slice.amount);
                            });

                            const sold = pack.slices.find(element => element.slice.amount == pack.amount);

                            totals.forEach(total => {

                                const existInPartial = partials.find(element => element.slice.amount == total.slice.amount) != null;
                                if (existInPartial) {
                                    const existInFinal = finals.find(element => element.slice.amount == total.slice.amount) != null;
                                    if (!existInFinal) {
                                        total.paid = true;
                                        finals.unshift(total);
                                    }
                                } else {
                                    const isSold = parseInt(total.slice.amount) + partialSum > parseInt(sold.slice.amount);
                                    if (!isSold) {
                                        const existInFinal = finals.find(element => element.slice.amount == total.slice.amount) != null;
                                        if (!existInFinal) {
                                            total.paid = false;
                                            finals.push(total);
                                        }
                                    }
                                }

                            });

                            const last = Object.create(totals.find((el) => parseInt(el.slice.amount) == parseInt(sold.slice.amount) - partialSum));
                            if (last) {
                                const existInFinal = finals.find(element => (element.slice.amount == last.slice.amount) && (element.paid == false)) != null;

                                if (!existInFinal) {
                                    last.paid = false;
                                    finals.push(last);
                                }
                            }

                            this.slices = finals;
                        } else {
                            this.$router.push({ path: "/" });
                        }
                    })
                    .catch((error) => {
                        this.$router.push({ path: "/" });
                    })
            } else {
                this.solded = true;
                await axios.post(this.$store.state.URL_API + "/inscription/check", {
                    token: this.$route.params.token
                })
                    .then((res) => {
                        if (res.data.status) {
                            this.inscription = res.data.data;
                            if (this.inscription.completed) {
                                this.over = true;
                            } else {
                                if (this.inscription.payment.pack.type == "ENFANT") {
                                    this.formChild = true;
                                }
                            }
                        } else {
                            this.$router.push({ path: "/" });
                        }
                    })
                    .catch((error) => {
                        this.$router.push({ path: "/" });
                    })
            }

            this.loading = false;
        },

        async pay() {
            this.loading = true;
            await axios.post(this.$store.state.URL_API + "/payment/update", {
                paymentId: this.payment.id,
                sliceId: this.selectedSliceId,
                userPhone: this.userPhone
            })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status) {
                        if (res.data.data.completed) {
                            this.step++;

                        } else {
                            // document.location.reload();
                            this.step = 1;
                            this.check();
                        }
                        this.userPhone = null;
                        toast.success("Votre paiement a réussi");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Erreur 500');
                })
            this.loading = false;
        },

        async submit() {
            this.loading = true;
            this.form.inscriptionId = this.inscription.id;
            await axios.post(this.$store.state.URL_API + "/inscription/submit", this.form)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status) {
                        if (res.data.status) {
                            toast.success("Formulaire soumis avec succès");
                            this.check();
                        } else {
                            // document.location.reload();
                        }
                        this.userPhone = null;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Erreur 500');
                })
            this.loading = false;
        },

        async printFile() { }
    }
});
</script>