import { createStore } from 'vuex'

export default createStore({
  state: {
    // 'URL_API': 'http://127.0.0.1:8000/api/v1',
    // 'URL': 'http://127.0.0.1:8000',
    'URL': 'https://camp-enfants-backend.onrender.com',
    'URL_API': 'https://camp-enfants-backend.onrender.com/api/v1',

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
