<template>
    <section id="templatemo_home">
        <div class="container">
            <div class="image-child">
                <img class="logo-home" src="@/assets/logo-camp.png" alt="Camp child" />
                <img class="logo-home" src="@/assets/camp-child.png" alt="Logo child">
            </div>
            <div class="templatemo_home_inner_wapper">
                <h1 class="text-center">EPICI Ministère des Enfants </h1>
            </div>
            <div class="templatemo_home_inner_wapper" style="max-width: 100%;">
                <h2 class="text-center" >Ami de Jésus ! Je maintiens la flamme de l'Esprit en moi</h2>
                <p class="text-center" style="max-width: 100%;">
                    Bienvenue sur la plateforme d'inscription au camp des enfants
                </p>
            </div>
            <div class="templatemo_home_inner_wapper btn_wapper">
                <div class="col-sm-6">
                    <a href="#templatemo_features" class="btn col-xs-12 scroll_effect shadow-top-down">
                        <i class="fa fa-money"></i> Paiement
                    </a>
                </div>
                <div class="col-sm-6">
                    <a href="#templatemo_contact" class="btn col-xs-12 scroll_effect shadow-top-down">
                        <i class="fa fa-user-plus" aria-hidden="true"></i> Inscription
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'HomeSection',
});
</script>
<style scoped>
.image-child{
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-home{
    width: 15rem;
    height: 15rem;
}

</style>