<template>
    <MenuCircle />
    <!-- Home -->
    <HomeSection />

    <!-- Paiements -->
    <PaymentSection />

    <!-- Inscription -->
    <InscriptionSection />

    <!-- Footer -->
    <FooterComp />
</template>
  
<script>

import InscriptionSection from "@/components/Inscription.vue";
import PaymentSection from "@/components/Payment.vue";
import FooterComp from "@/components/Footer.vue";
import HomeSection from "@/components/Home.vue";
import MenuCircle from "@/components/Menu.vue";
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'HomeView',
    components: {
        InscriptionSection,
        PaymentSection,
        HomeSection,
        FooterComp,
        MenuCircle
    },
});

</script>
  