<template>
    <section id="templatemo_features">
        <div class="container-fluid">
            <header class="template_header">
                <h1 class="text-center">
                    <a v-on:click="stepback()" v-if="step != 1 && step != 6" class="btn" style="height: 100%;">Retour</a>

                    Paiement: {{ selectedPack.title }}
                </h1>
            </header>

            <div v-if="!loading">
                <div class="col-sm-12 text-center" style="align-items: center; justify-content: center;" v-if="step == 1">
                    <div class="col-sm-6 col-lg-3 feature-box" v-for="pack in packs" v-bind:key="pack.id">
                        <div class="feature-box-inner">
                            <p style="font-size: 30px; font-weight: 600;">
                                {{ pack.title }}
                            </p>
                            <p style="font-size: 20px; font-weight: 100; padding: 0;">
                                {{ pack.description }}
                            </p>
                            <p>
                                {{ cashFormat(pack.amount) }}
                            </p>
                            <div class="templatemo_home_inner_wapper btn_wapper">
                                <div class="col-sm-12">
                                    <a v-on:click="selectPack(pack.id)" class="btn col-xs-12 scroll_effect shadow-top-down">
                                        Sélectionner
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  -->
                <div class="col-sm-12" v-else-if="step == 2">

                    <!--  -->
                    <div class="col-sm col-lg-3 feature-box" v-for="slice in selectedPack.slices">
                        <div class="feature-box-inner" v-if="slice.slice.amount != selectedPack.amount">
                            <p style="font-size: 30px; font-weight: 600;">
                                Tranche 0{{ selectedPack.slices.indexOf(slice) + 1 }}
                            </p>
                            <p>
                                {{ cashFormat(slice.slice.amount) }}
                            </p>

                            <div class="templatemo_home_inner_wapper btn_wapper">
                                <div class="col-sm-12">
                                    <a v-on:click="selectSlice(slice.slice.id)"
                                        class="btn col-xs-12 scroll_effect shadow-top-down">
                                        Payer maintenant
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div v-else class="feature-box-inner" style="background-color: rgb(238, 136, 63);">
                            <p style="font-size: 30px; font-weight: 600;">
                                Soldé

                            </p>
                            <p>
                                {{ cashFormat(slice.slice.amount) }}
                            </p>

                            <div class="templatemo_home_inner_wapper btn_wapper">
                                <div class="col-sm-12">
                                    <a v-on:click="selectSlice(slice.slice.id)"
                                        class="btn col-xs-12 scroll_effect shadow-top-down">
                                        Payer maintenant
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  -->
                <form v-on:submit="pay()" v-else-if="step == 3">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-addon"><i class="fa fa-phone"></i></div>
                            <input v-model="userPhone" type="text" name="name" class="form-control" id="contact-name"
                                placeholder="Inscrivez votre numéro de téléphone" style="color: white;" required
                                maxlength="10" minlength="10">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-xs-6 col-xs-offset-6">
                            <button type="submit" class="form-control" style="color: white;">Je paie</button>
                        </div>

                    </div>
                    <div class="col-sm-12">
                        <header class="template_header">
                            <h1 class="text-center"></h1>
                        </header>
                    </div>
                </form>
                <!--  -->

                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;" v-else>
                    <p class="text-center" style="color: azure; font-weight: bold;">Paiment effectué avec succès.<br></p>
                    <!--  -->
                    <div class="templatemo_home_inner_wapper btn_wapper">
                        <div class="col-sm-12">
                            <a href="#templatemo_contact" class="btn col-xs-12 scroll_effect shadow-top-down">
                                Procéder à l'inscription
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!--  -->
            <div v-else>
                <square color="#fff"></square>
            </div>


        </div>
    </section>
</template>

<script>
import { cashFormat } from '@/assets/utils/cash-utils.js';
import { toast } from "@/assets/utils/toast.js";
import { defineComponent } from 'vue';
import axios from "axios";


export default defineComponent({
    name: 'PaymentSection',
    data() {
        return {
            packs: [],
            //
            selectedPack: { title: "" },
            selectedSlice: {},
            //
            loading: true,
            //
            userPhone: null,
            //
            step: 1
        }
    },

    mounted() {
        this.getPacks();
    },

    methods: {
        cashFormat,

        async getPacks() {
            this.loading = true;
            await axios.get(this.$store.state.URL_API + "/pack/all")
                .then((res) => {
                    if (res.data.status) {
                        this.packs = res.data.data
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Erreur 500');
                })
            this.loading = false;
        },

        async pay() {
            this.loading = true;
            await axios.post(this.$store.state.URL_API + "/payment/make", {
                packId: this.selectedPack.id,
                sliceId: this.selectedSlice.slice.id,
                userPhone: this.userPhone
            })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status) {
                        if (res.data.data.completed) {
                            this.step++;
                        } else {
                            // document.location.reload();
                        }
                        this.userPhone = null;
                        toast.success("Votre paiement a réussi. Un code de paiement a été envoyé par sms. Utilisé le pour procéder à l'inscription.");
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Erreur 500');
                })
            this.loading = false;
        },

        load(action) {
            this.loading = true;
            setTimeout(() => {
                action();
                this.loading = false;
            }, 1500);
        },

        selectPack(id) {
            this.load(() => {
                this.selectedPack = this.packs.find((t) => t.id == id);
                this.step++;
            });
        },

        selectSlice(id) {
            this.load(() => {
                this.selectedSlice = this.selectedPack.slices.find((t) => t.slice.id == id);
                this.step++;
            });
        },

        stepback() {
            this.step--;
            if (this.step == 1) {
                this.selectedPack = { title: "" };
            }
            this.selectedSlice = null;
        }
    }
});
</script>